import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Teilen und Weiterleiten von Kandidaten",
  "description": "Welche Möglichkeiten habe ich einen Kandidaten zu teilen / weiterzuleiten?",
  "author": "Jochen Ritscher",
  "categories": ["getting-started"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Die Link-Weiterleitung setzt eine vorangegangene Berechtigung voraus, ist also nur ein reines Weiterleiten.`}</p>
    <p>{`Mit der Einladung auf einen Job erhält er Eingeladene Zugriff auf alle für den Job eingegangenen Bewerbungen.`}</p>
    <p>{`Mit der Einladung für einen Bewerber erhält der Eingeladene sowohl den Link als auch die Berechtigung auf den Bewerber zugreifen zu können.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      